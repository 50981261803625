import {
  Platform,
  generateEventMetadata,
  getElementFullDOMPath,
  getMappingFromDivID,
  getUserInitialTimestamp,
} from "@helper/helper";
import { VideoJsPlayer } from "video.js";

import { generateEventId } from "@browser/plugins/ids/eventId";
import { getDataFromLocalStorage } from "@browser/plugins/ids/window";
import { CreateEventOptions, TrackedEvent } from "@core/event";

export type BrowserEventType =
  | "custom"
  | "click"
  | "load"
  | "scrollDepth"
  | "id"
  | "initSession"
  | "unload"
  | "resume"
  | "unfocusedPageOpen"
  | "elementVisible"
  | "podcasts"
  | "videos"
  | `dataLayer.${string}`
  | "permutive"
  | "cavaiAds";

export type PodcastEventType = "ready" | "play" | "pause" | "end" | "progress";
export type VideoEventType =
  | "ready"
  | "play"
  | "pause"
  | "end"
  | "volumeChange"
  | "progress"
  | "fullScreenToggle"
  | "seeked"
  | "error";

export type PermutiveIdentificationData = {
  pdfps: string | null;
  permutiveDataEnrichers: string | null;
  permutiveApp: string | null;
  permutiveId: string | null;
  permutiveSession: string | null;
  permutiveDataMisc: string | null;
  permutiveDataModels: string | null;
  permutiveDataQueries: string | null;
  permutiveConsent: string | null;
  permutiveUnprocessedPba: string | null;
  permutiveTpd: string | null;
};

export type ClickEventData = {
  targetUrl: string | null;
  elementId?: string | null;
  elementClasses?: string[] | null;
  elementText?: string | null;
  elementTarget?: string | null;
  clickCategory: string | null;
  currentWindowUrl: string | null;
  xCoordinate: number | null;
  yCoordinate: number | null;
  fullDOMPath: string | null;
  campaignAttributes?: AdCampaignAttributes;
};

export type AdCampaignAttributes = {
  adLineItemID: string | null;
  adCreativeID: string | null;
  adCampaignID: string | null;
  adAdvertiserID: string | null;
  dataGoogleQueryID: string | null;
};

export type LoadEventData = {
  currentWindowUrl?: string | null;
  referrer?: string | null;
  pageTitle?: string | null;
};

export type ScrollDepthEventData = {
  currentWindowUrl: string | null;
  scrollDepthPercentage: number | null;
  pageTitle?: string | null;
};

export type PodcastEventData = {
  action: PodcastEventType;
  src: string | null;
  mode: "playlist" | "single";
  iFrameId: string | null;
  iFrameTitle: string | null;
  audioPosition: number | null;
  totalDuration: number | null;
};

export type VideoEventData = {
  action: VideoEventType;
  src: string | null;
  videoId: string | null;
  videoPlaylistId: string | null;
  videoDataId: string | null;
  videoDataAccount: string | null;
  videoPosition: number | null;
  videoVolume: number | null;
  videoDuration: number | null;
};

export type IdentificationEventData = {
  identificationName: string | null;
  identificationValue: string | object | null;
};

export type UnloadEventData = {
  browsingSessionDuration: number | null;
  browsingSessionDurationMilliseconds: number | null;
};

export type UnfocusedPageOpenEventData = {
  originUrl: string | null;
};

export type EventToTrackData = {
  dataLocation: string;
  identifier: string;
};

export type ElementVisibleEventData = {
  elementId?: string | null;
  elementClasses?: string[] | null;
  elementAttributes?: Record<string, string> | null;
  elementType: string | null;
  elementXCoordinate: number | null;
  elementYCoordinate: number | null;
  elementDOMPath: string | null;
  timeToAppear: number | null;
};

export type CavaiAdsEventData = {
  userId: string | null;
  cavaiEventDateTime: string | null;
  responseStep: number | null;
  responsePrompt: string | null;
  responseText: string | null;
  responseType: string | null;
};

export type BrowserTrackedEvent<E extends BrowserEventType = BrowserEventType> =
  TrackedEvent<E, BrowserEventDataMapping>;

export type EventArticleMetadata = {
  abVariant: string | null;
  adBlocker: string | null;
  articleId: string | null;
  articleCount: string | null;
  author: string | null;
  cueArticleId: string | null;
  keyword: string | null;
  page: string | null;
  chapter1: string | null;
  chapter2: string | null;
  chapter3: string | null;
  chapter1En: string | null;
  chapter2En: string | null;
  chapter3En: string | null;
  level2: string | null;
  level2Ga: string | null;
  level2Local: string | null;
  contentCat: string | null;
  contentType: string | null;
  pubDate: string | null;
  content_publication_utc: string | null;
  content_last_updated_utc: string | null;
  title: string | null;
  titleGa: string | null;
  visitorCat: string | null;
};

export type MagazineArticleMetadata = EventArticleMetadata & {
  content_url: string | null;
  article_type: string | null;
};

export type EventDataLayerMetadata = { [key: string]: unknown };

export type EventIdentificationyMetadata = {
  suid: string | null;
  mysphw: string | null;
  visitId: string | null;
  incomingNeuronId: string | null;
  permutiveId: string | null;
};

export type EventUTMMetadata = {
  utmCampaign: string | null;
  utmContent: string | null;
  utmMedium: string | null;
  utmSource: string | null;
  utmTerm: string | null;
};

export type EventMetaData = {
  currentWindowURL: string;
  neuronVersion: string;
  platformType: Platform;
  identification: EventIdentificationyMetadata;
  article: EventArticleMetadata;
  utm: EventUTMMetadata;
  device: DeviceInformation;
  dataLayer: EventDataLayerMetadata;
};

export type DeviceInformation = {
  userAgent: string | null;
  userPreferredLanguage: string | null;
  screenHeight: number | null;
  screenWidth: number | null;
  screenMaxTouchPoints: number | null;
  screenOrientation: string | null;
};

export type BrowserEventDataMapping = {
  custom: unknown;
  load: LoadEventData;
  scrollDepth: ScrollDepthEventData;
  click: ClickEventData;
  id: IdentificationEventData;
  initSession: null;
  unload: UnloadEventData;
  resume: null;
  unfocusedPageOpen: UnfocusedPageOpenEventData;
  elementVisible: ElementVisibleEventData;
  podcasts: PodcastEventData;
  videos: VideoEventData;
  permutive: PermutiveIdentificationData;
  cavaiAds: CavaiAdsEventData;
} & { [key in `dataLayer.${string}`]: unknown };

export const createDataLayerEvent = (
  eventDataLayerType: string,
  eventData: object,
  options?: CreateEventOptions,
) => {
  const dataLayerEvent: BrowserTrackedEvent<`dataLayer.${typeof eventDataLayerType}`> =
    {
      data: {
        ...eventData,
        dataLayerIdentifier: `${eventDataLayerType}`,
      },
      eventDateTime: new Date().toISOString(),
      eventType: `dataLayer.${eventDataLayerType}`,
      eventId: generateEventId(),
      meta: generateEventMetadata(options?.metadataOptions),
    };

  return dataLayerEvent;
};

export const createResumeEvent = (options?: CreateEventOptions) => {
  const resumeEvent: BrowserTrackedEvent<"resume"> = {
    data: null,
    eventDateTime: new Date().toISOString(),
    eventType: "resume",
    eventId: generateEventId(),
    meta: generateEventMetadata(options?.metadataOptions),
  };

  return resumeEvent;
};

export const createPermutiveEvent = (
  options?: CreateEventOptions,
): BrowserTrackedEvent<"permutive"> | null => {
  const permutiveData: PermutiveIdentificationData = {
    pdfps: getDataFromLocalStorage("_pdfps"),
    permutiveApp: getDataFromLocalStorage("permutive-app"),
    permutiveId: getDataFromLocalStorage("permutive-id"),
    permutiveDataEnrichers: getDataFromLocalStorage("permutive-data-enrichers"),
    permutiveSession: getDataFromLocalStorage("permutive-session"),
    permutiveDataMisc: getDataFromLocalStorage("permutive-data-misc"),
    permutiveDataModels: getDataFromLocalStorage("permutive-data-models"),
    permutiveDataQueries: getDataFromLocalStorage("permutive-data-queries"),
    permutiveConsent: getDataFromLocalStorage("permutive-consent"),
    permutiveUnprocessedPba: getDataFromLocalStorage(
      "permutive-unprocessed-pba",
    ),
    permutiveTpd: getDataFromLocalStorage("permutive-data-tpd"),
  };

  // Checks if all values in the map is null. If yes, we do not want to send the event.
  const allNullValues = Object.values(permutiveData).every(
    (value) => value === null,
  );

  if (allNullValues) {
    return null;
  }

  const permutiveEvent: BrowserTrackedEvent<"permutive"> = {
    data: permutiveData,
    eventDateTime: new Date().toISOString(),
    eventType: "permutive",
    eventId: generateEventId(),
    meta: generateEventMetadata(options?.metadataOptions),
  };

  return permutiveEvent;
};

export const createElementVisibleEvent = (
  element: HTMLElement,
  entry: IntersectionObserverEntry,
  options?: CreateEventOptions,
): BrowserTrackedEvent<"elementVisible"> => {
  const boundingRect: DOMRect = element.getBoundingClientRect();
  const scrollLeft =
    window.pageXOffset ||
    document.documentElement.scrollLeft ||
    document.body.scrollLeft;
  const scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;
  const xCoordinate: number = boundingRect.left + scrollLeft;
  const yCoordinate: number = boundingRect.top + scrollTop;

  const elementDOMPath: string = getElementFullDOMPath(element);
  const elementId: string = element.id;
  const elementType: string = getMappingFromDivID(elementId);
  const elementClasses: string[] = [...Array.from(element.classList)];
  const timeToAppear: number = entry.time;

  const attributeMap: Record<string, string> = Array.from(
    element.attributes,
  ).reduce((map, attribute) => {
    map[attribute.name] = attribute.value;
    return map;
  }, {});

  const elementVisibleEventData: ElementVisibleEventData = {
    elementId: elementId,
    elementClasses: elementClasses,
    elementAttributes: attributeMap,
    elementType: elementType,
    elementXCoordinate: xCoordinate,
    elementYCoordinate: yCoordinate,
    elementDOMPath: elementDOMPath,
    timeToAppear: timeToAppear,
  };

  const elementVisibleEvent: BrowserTrackedEvent<"elementVisible"> = {
    data: elementVisibleEventData,
    eventDateTime: new Date().toISOString(),
    eventType: "elementVisible",
    eventId: generateEventId(),
    meta: generateEventMetadata(options?.metadataOptions),
  };

  return elementVisibleEvent;
};

export const createUnfocusedPageOpenEvent = (options?: CreateEventOptions) => {
  const unfocusedPageOpenEvent: BrowserTrackedEvent<"unfocusedPageOpen"> = {
    data: { originUrl: document.referrer },
    eventDateTime: new Date().toISOString(),
    eventType: "unfocusedPageOpen",
    eventId: generateEventId(),
    meta: generateEventMetadata(options?.metadataOptions),
  };

  return unfocusedPageOpenEvent;
};

export const createUnloadEvent = (options?: CreateEventOptions) => {
  const initialUserTimestamp = getUserInitialTimestamp();
  const currentTimestamp = new Date(new Date().toISOString());

  if (initialUserTimestamp) {
    const diffInSeconds = Math.floor(
      (currentTimestamp.valueOf() - initialUserTimestamp.valueOf()) / 1e3,
    );
    const diffInMilliseconds =
      currentTimestamp.valueOf() - initialUserTimestamp.valueOf();

    const unloadEventData: UnloadEventData = {
      browsingSessionDuration: diffInSeconds,
      browsingSessionDurationMilliseconds: diffInMilliseconds,
    };

    const unloadEvent: BrowserTrackedEvent<"unload"> = {
      data: unloadEventData,
      eventDateTime: new Date().toISOString(),
      eventType: "unload",
      eventId: generateEventId(),
      meta: generateEventMetadata(options?.metadataOptions),
    };

    return unloadEvent;
  }
};

export const createLoadEvent = (options?: CreateEventOptions) => {
  const loadEventData: LoadEventData = {
    currentWindowUrl: window.location.href,
    pageTitle: document.title,
    referrer: document.referrer,
  };

  const browserLoadEvent: BrowserTrackedEvent<"load"> = {
    data: loadEventData,
    eventDateTime: new Date().toISOString(),
    eventType: "load",
    eventId: generateEventId(),
    meta: generateEventMetadata(options?.metadataOptions),
  };
  return browserLoadEvent;
};

export const createVideoEvent = (
  action: VideoEventType,
  videoPlayer: VideoJsPlayer,
  options?: CreateEventOptions,
): BrowserTrackedEvent<"videos"> | null => {
  const videoSrc: string | null = videoPlayer.src();
  const videoId: string | null = videoPlayer.id();
  const videoDataId: string | null = videoPlayer.getAttribute("data-video-id");
  const videoDuration: number = Math.trunc(videoPlayer.duration());
  const videoCurrentPosition: number = Math.trunc(videoPlayer.currentTime());
  const videoVolume: number = videoPlayer.volume() * 100.0;
  const videoPlaylistId: string | null =
    videoPlayer.getAttribute("data-playlist-id");
  const videoDataAccount: string | null =
    videoPlayer.getAttribute("data-account");

  const videoEventData: VideoEventData = {
    action: action,
    src: videoSrc,
    videoId: videoId,
    videoPlaylistId: videoPlaylistId,
    videoDataId: videoDataId,
    videoDataAccount: videoDataAccount,
    videoPosition: videoCurrentPosition,
    videoVolume: videoVolume,
    videoDuration: videoDuration,
  };

  const videoEvent: BrowserTrackedEvent<"videos"> = {
    data: videoEventData,
    eventDateTime: new Date().toISOString(),
    eventType: "videos",
    eventId: generateEventId(),
    meta: generateEventMetadata(options?.metadataOptions),
  };

  return videoEvent;
};

export const createPodcastEvent = (
  action: PodcastEventType,
  frame: Element,
  currentAudioPosition: number,
  totalDuration: number,
  options?: CreateEventOptions,
): BrowserTrackedEvent<"podcasts"> | null => {
  const podcastSrc: string | null = frame.getAttribute("src");
  const iFrameId: string | null = frame.getAttribute("id");
  const iFrameTitle: string | null = frame.getAttribute("title");

  /**
   * If the src URL contains the 'playlists' word, it is in the playlist mode,
   * else, it will mean that the podcast is in 'single' mode.
   */
  const isPlaylistMode: boolean = podcastSrc?.includes("playlists")
    ? true
    : false;

  const podcastEventData: PodcastEventData = {
    action: action,
    mode: isPlaylistMode ? "playlist" : "single",
    src: podcastSrc,
    iFrameId: iFrameId,
    iFrameTitle: iFrameTitle,
    audioPosition: currentAudioPosition,
    totalDuration: totalDuration,
  };

  const podcastEvent: BrowserTrackedEvent<"podcasts"> = {
    data: podcastEventData,
    eventDateTime: new Date().toISOString(),
    eventType: "podcasts",
    eventId: generateEventId(),
    meta: generateEventMetadata(options?.metadataOptions),
  };

  return podcastEvent;
};
