import { generateEventId } from "@browser/plugins/ids/eventId";
import { CreateEventOptions, TrackedEvent, UnloadEventData } from "@core/event";
import { Queuable } from "@core/queue";
import {
  generateEventMetadata,
  getUserInitialTimestamp,
  removeInitialTimestamp,
} from "@helper/helper";

let _queue: Queuable;
let _options: CreateEventOptions;

function initialize(queue, options) {
  _queue = queue;
  _options = options;
  window.addEventListener("beforeunload", handler);
  window.addEventListener("blur", handler);
}

function cleanup() {
  window.removeEventListener("beforeunload", handler);
  window.removeEventListener("blur", handler);
}

export default {
  initialize,
  cleanup,
};

function handler() {
  trackUnloadEvent();
}

function trackUnloadEvent(): void {
  const initialUserTimestamp = getUserInitialTimestamp();
  const currentTimestamp = new Date(new Date().toISOString());

  if (initialUserTimestamp) {
    const diffInSeconds = Math.floor(
      (currentTimestamp.valueOf() - initialUserTimestamp.valueOf()) / 1e3,
    );
    const diffInMilliseconds =
      currentTimestamp.valueOf() - initialUserTimestamp.valueOf();

    const unloadEventData: UnloadEventData = {
      browsingSessionDuration: diffInSeconds,
      browsingSessionDurationMilliseconds: diffInMilliseconds,
    };

    const unloadEvent: TrackedEvent<"unload"> = {
      data: unloadEventData,
      eventDateTime: new Date().toISOString(),
      eventType: "unload",
      eventId: generateEventId(),
      meta: generateEventMetadata(_options?.metadataOptions),
    };
    if (unloadEvent) {
      _queue.push(unloadEvent);
      _queue.flush(true);
      removeInitialTimestamp();
    }
  }
}
