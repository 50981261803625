/**
 * @fileoverview This file contains the main logic for Neuron (Script Version)
 * The script version of Neuron prepares the SDK to be used in a <script> tag inside
 * of a HTML page.
 */

import {
  BrowserEventQueue,
  BrowserQueueConfig,
  SessionStorageKey,
} from "@browser/browserQueue";
import {
  AdCampaignAttributes,
  BrowserEventType,
  BrowserTrackedEvent,
  ClickEventData,
  createDataLayerEvent,
  createElementVisibleEvent,
  createLoadEvent,
  createPermutiveEvent,
  createPodcastEvent,
  createResumeEvent,
  createUnfocusedPageOpenEvent,
  createVideoEvent,
} from "@browser/event";
import {
  decorateOutgoingLinks,
  parseIncomingLink,
} from "@browser/plugins/tools/linkDecoration";
import {
  calculateScrollPercentage,
  fireScrollDepthEvents,
} from "@browser/scrollDepth";
import {
  onGenerateMagazineArticleMetadata,
  onGetDataLayer,
} from "@core/plugins/dataLayer";
import {
  EventMetadataOptions,
  generateEventMetadata,
  getClickMappingFromElementID,
  getElementFullDOMPath,
  persistInitialTimestamp,
} from "@helper/helper";
import * as playerjs from "player.js";
import videojs, { VideoJsPlayer } from "video.js";

import { generateEventId } from "@browser/plugins/ids/eventId";
import { getNeuronId } from "@browser/plugins/ids/neuronId";
import { generateVisitId } from "@browser/plugins/ids/visitId";
import { getToken, TokenValue } from "@browser/token";
import neuronConfig from "@config/config.json";
import { ExperimentalOptions } from "@core/index";
import featureUnloadTracker from "@features/browser/trackBeforeUnload";
import featureCavaiAdsTrackEvent from "@features/cavaiAds/trackEvent";
import featureEP2 from "@features/ep2";
import { isBrowserSafari } from "@helper/helper";

declare global {
  interface HTMLElement {
    _neuronClickTracked?: boolean;
    _neuronVisibleTracked?: boolean;
  }

  interface Window {
    _elementMutationObservers?: Map<HTMLElement, MutationObserver>;
    _smtObserver?: MutationObserver;
    onYouTubeIframeAPIReady: any;
    dataLayer: object[];
  }
}

interface TrackEventBaseConfig {
  eventType: BrowserEventType;
}

export interface TrackClickConfig extends TrackEventBaseConfig {
  eventType: "click";
  selector: string;
}

export interface TrackScrollDepthConfig extends TrackEventBaseConfig {
  eventType: "scrollDepth";
  minimumPixelY: number;
}

export interface ElementVisibleConfig extends TrackEventBaseConfig {
  eventType: "elementVisible";
  selector: string;
}

export type TrackEventConfig =
  | TrackClickConfig
  | TrackScrollDepthConfig
  | ElementVisibleConfig;

// Requires only 1 of 2 properties from interface.
export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type TrackableEvents = {
  idEvents: Array<{
    dataLocation: string;
    identifier: string;
  }>;
};

export interface IBrowserConfig {
  trackedBrowserEvents: TrackEventConfig[];
  sessionApiUrl: string;
  sessionApiToken: string;
  flushRate?: number;
  experimental?: ExperimentalOptions;
}

export type BrowserConfig = IBrowserConfig;

export type BrowserTracker = {
  track: (ev: BrowserTrackedEvent<BrowserEventType>) => void;
  queue: BrowserEventQueue;
};

const intervalIdEventKeysToBeDeleted: string[] = [];
const intervalIdsForEvents: Record<string, number> = {};
const defaultFlushRateInMS = 250;
const defaultCheckRateForDataLayerEvents = 250; // DataLayer not to be confused with ._data. DataLayer is primarily for GTM events
const eventMetadataOptions: EventMetadataOptions = {
  platformType: "script",
};

export const attachVideoListeners = (queue: BrowserEventQueue) => {
  /**
   * This method allows us to start listening on events for videos playing.
   * We will need to cater for both scenarios whereby the video is either
   * hosted on Brightcove (<video> tags) or YouTube embedded videos (iFrames).
   *
   * NOTE: Tracking videos via the video tags or iFrame tags is highly subjected
   * to the vendor's implementation. The risk is accepted and tolerated that will require
   * us to update our implementation, in the event that the vendor updates their naming conventions.
   */
  const allVideoPlayers: NodeListOf<HTMLVideoElement> =
    document.querySelectorAll("video");

  if (allVideoPlayers.length > 0) {
    allVideoPlayers.forEach((eachVideo: HTMLVideoElement) => {
      const videoPlayer: VideoJsPlayer = videojs(eachVideo);
      createVideoEventListeners(videoPlayer, queue);
    });
  }
};

const createVideoEventListeners = (
  videoPlayer: VideoJsPlayer,
  queue: BrowserEventQueue,
) => {
  let previousVideoPosition = 0;
  neuronConfig.videoListenerEvents.forEach((eachVideoEvent) => {
    videoPlayer.on(eachVideoEvent, function () {
      const videoListenerMappedEvent =
        neuronConfig.videoEventMapping[eachVideoEvent];
      if (eachVideoEvent == "timeupdate") {
        const currentTime = Math.trunc(videoPlayer.currentTime());
        if (Math.abs(currentTime - previousVideoPosition) >= 1) {
          previousVideoPosition = currentTime;
          const videoEvent = createVideoEvent(
            videoListenerMappedEvent,
            videoPlayer,
            { metadataOptions: eventMetadataOptions },
          );
          if (videoEvent) {
            queue.push(videoEvent);
          }
        }
      } else {
        const videoEvent = createVideoEvent(
          videoListenerMappedEvent,
          videoPlayer,
        );
        if (videoEvent) {
          queue.push(videoEvent);
        }
      }
    });
  });
};

export const attachPodcastListeners = (queue: BrowserEventQueue) => {
  /**
   * This method allows us to start listening on events for podcasts playing.
   * There is a distinction between a single podcast playing and podcasts in a playlist.
   * For single podcasts, the querySelector to use will follow this prefix based on the id: iframe-field_embed_iframe-<id>
   * For playlists, the querySelector to use will follow this prefix based on the src:
   *       https://omny.fm/shows/the-business-times-podcasts-1/playlists/podcast/embed?style=cover
   *       https://omny.fm/shows/the-business-times-podcasts-1/playlists/podcast/*
   *
   * NOTE: Tracking podcasts has been accepted with the risk that the src/id patterns on the iFrame might
   * change at any given moment. This is unfortunately inevitable due to the podcasts being hosted on a
   * 3rd party platform.
   */
  const queryConditions: string[] = [
    '[src^="https://omny.fm/shows/"]',
    '[id^="iframe-field_embed_iframe-"]',
  ];
  const querySelectorCondition: string = queryConditions
    .map((item) => `iframe${item}`)
    .join(", ");
  const podcastFrames: NodeListOf<Element> = document.querySelectorAll(
    querySelectorCondition,
  );

  if (podcastFrames.length > 0) {
    /**
     * If there is a presence of iFrames that matches up for podcasts,
     * we will load up playerjs to start monitoring for real-time data
     */
    podcastFrames.forEach((eachFrame: Element) => {
      const player = new playerjs.Player(eachFrame);
      let previousAudioPosition = 0;
      let totalPodcastDuration = 0;

      player.on("ready", () => {
        player.getDuration((podcastDuration: number) => {
          totalPodcastDuration = Math.floor(podcastDuration);
        });
        player.getCurrentTime((currentAudioPosition: number) => {
          const podcastReadyEvent: BrowserTrackedEvent<"podcasts"> | null =
            createPodcastEvent(
              "ready",
              eachFrame,
              Math.floor(currentAudioPosition),
              totalPodcastDuration,
              { metadataOptions: eventMetadataOptions },
            );
          if (podcastReadyEvent) {
            queue.push(podcastReadyEvent);
          }
        });

        player.on("play", () => {
          player.getDuration((podcastDuration: number) => {
            totalPodcastDuration = Math.floor(podcastDuration);
          });
          player.getCurrentTime((currentAudioPosition: number) => {
            const podcastPlayEvent: BrowserTrackedEvent<"podcasts"> | null =
              createPodcastEvent(
                "play",
                eachFrame,
                Math.floor(currentAudioPosition),
                totalPodcastDuration,
                { metadataOptions: eventMetadataOptions },
              );
            if (podcastPlayEvent) {
              queue.push(podcastPlayEvent);
            }
          });
        });

        player.on("ended", () => {
          player.getDuration((podcastDuration: number) => {
            totalPodcastDuration = Math.floor(podcastDuration);
          });
          player.getCurrentTime((currentAudioPosition: number) => {
            const podcastEndedEvent: BrowserTrackedEvent<"podcasts"> | null =
              createPodcastEvent(
                "end",
                eachFrame,
                Math.floor(currentAudioPosition),
                totalPodcastDuration,
                { metadataOptions: eventMetadataOptions },
              );
            if (podcastEndedEvent) {
              queue.push(podcastEndedEvent);
            }
          });
        });

        player.on("pause", () => {
          player.getDuration((podcastDuration: number) => {
            totalPodcastDuration = Math.floor(podcastDuration);
          });
          player.getCurrentTime((currentAudioPosition: number) => {
            const podcastPauseEvent: BrowserTrackedEvent<"podcasts"> | null =
              createPodcastEvent(
                "pause",
                eachFrame,
                Math.floor(currentAudioPosition),
                totalPodcastDuration,
                { metadataOptions: eventMetadataOptions },
              );
            if (podcastPauseEvent) {
              queue.push(podcastPauseEvent);
            }
          });
        });

        player.on("timeupdate", (data: any) => {
          player.getDuration((podcastDuration: number) => {
            totalPodcastDuration = Math.floor(podcastDuration);
          });

          const audioPosition: number = Math.floor(data.seconds);
          if (Math.abs(audioPosition - previousAudioPosition) >= 1) {
            previousAudioPosition = audioPosition;
            const podcastPauseEvent: BrowserTrackedEvent<"podcasts"> | null =
              createPodcastEvent(
                "progress",
                eachFrame,
                audioPosition,
                Math.floor(data.duration),
                { metadataOptions: eventMetadataOptions },
              );
            if (podcastPauseEvent) {
              queue.push(podcastPauseEvent);
            }
          }
        });
      });
    });
  }
};

export const attachEventListeners = (queue: BrowserEventQueue) => {
  featureUnloadTracker.initialize(queue, {
    metadataOptions: eventMetadataOptions,
  });
  addFocusEventListener(queue);
  addGTMDataLayerListener(queue);
};

const addGTMDataLayerListener = (queue: BrowserEventQueue) => {
  window.setInterval(() => {
    checkDataLayer(queue);
  }, defaultCheckRateForDataLayerEvents);
};

const checkDataLayer = (queue: BrowserEventQueue) => {
  // Get events from DataLayer
  const dataLayerEvents = window.dataLayer;
  if (dataLayerEvents && dataLayerEvents.length > 0) {
    // We are only interested in events that do not start with "gtm."
    const filteredEvents: object[] = dataLayerEvents.filter(
      (eachDataLayerEvent) => {
        const eventName: string = eachDataLayerEvent["event"];
        if (!eventName) return false;
        return !eventName.startsWith("gtm.");
      },
    );
    // Once we got the events that we are interested in, time to check for uniqueness by comparing it to those stored in sessionStorage
    checkForUniqueEventsAndAddToQueue(queue, filteredEvents);
  }
};

const checkForUniqueEventsAndAddToQueue = (
  queue: BrowserEventQueue,
  filteredEvents: object[],
) => {
  const eventsThatHaveBeenFiredToHighway: string | null =
    window.sessionStorage.getItem(SessionStorageKey.EventsInDataLayer);
  if (eventsThatHaveBeenFiredToHighway) {
    // if there are existing records in session storage, we need to compare them and get only the unique ones to be sent in the queue
    const parsedEventsArray: object[] = JSON.parse(
      eventsThatHaveBeenFiredToHighway,
    );

    const presentSetOfUniqueKeysFromSessionStorage = new Set(
      parsedEventsArray.map((eachEvent) => eachEvent["gtm.uniqueEventId"]),
    );
    const uniqueEvents = filteredEvents.filter(
      (eachEvent) =>
        !presentSetOfUniqueKeysFromSessionStorage.has(
          eachEvent["gtm.uniqueEventId"],
        ),
    );
    getDataLayerEventTypeAndAddEventsToQueue(queue, uniqueEvents);
  } else {
    // if session storage does not have existing records, we can immediately add them to the queue and store a fresh set
    getDataLayerEventTypeAndAddEventsToQueue(queue, filteredEvents);
  }

  window.sessionStorage.setItem(
    SessionStorageKey.EventsInDataLayer,
    JSON.stringify(filteredEvents),
  );
};

const getDataLayerEventTypeAndAddEventsToQueue = (
  queue: BrowserEventQueue,
  dataLayerEvents: object[],
) => {
  dataLayerEvents.forEach((eachEvent) => {
    const eventName: string = eachEvent["event"];
    const dataLayerEventType: string | null =
      getDataLayerKeyFromEventName(eventName);
    if (dataLayerEventType) {
      // Create an event and add to queue
      addDataLayerEventToQueue(queue, dataLayerEventType, eachEvent);
    }
  });
};

const getDataLayerKeyFromEventName = (eventName: string): string | null => {
  for (const key in neuronConfig.dataLayerEvents) {
    if (neuronConfig.dataLayerEvents[key].includes(eventName)) {
      return key;
    }
  }

  return null;
};

const addFocusEventListener = (queue: BrowserEventQueue) => {
  /*
    Reinstate flushing on periodic intervals upon user's focus back on the site,
    along with the reinstatement of the timestamp to localStorage when the user
    has returned to the site
  */
  const callback = () => {
    if (Object.keys(intervalIdsForEvents).length == 0) {
      addResumeEventToQueue(queue);
      persistInitialTimestamp();
    }
  };
  const debouncedCallback = debounce(callback, 500);
  window.addEventListener(
    "focus",
    isBrowserSafari() ? debouncedCallback : callback,
  );
};

export const attachUserSpecifiedListeners = (
  config: BrowserConfig,
  queue: BrowserEventQueue,
) => {
  config.trackedBrowserEvents.forEach((eventConfig) => {
    if (
      eventConfig.eventType !== "click" &&
      eventConfig.eventType !== "elementVisible"
    ) {
      return;
    }

    const elements: HTMLElement[] = Array.from(
      document.querySelectorAll(eventConfig.selector) ?? [],
    );

    if (eventConfig.eventType === "click") {
      attachClickEvents(queue, elements);
    }

    if (eventConfig.eventType === "elementVisible") {
      attachElementVisibleEvents(queue, elements);
    }
  });
};

const attachElementVisibleEvents = (
  queue: BrowserEventQueue,
  elements: HTMLElement[],
) => {
  elements.forEach((element: HTMLElement) => {
    if (!element._neuronVisibleTracked) {
      createIntersectionObserver(element, queue);
      element._neuronVisibleTracked = true;
    }
  });
};

const attachElementMutationObserver = (
  element: HTMLElement,
  entry: IntersectionObserverEntry,
  queue: BrowserEventQueue,
) => {
  const mutationObserver = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      const elementVisibleEvent: BrowserTrackedEvent<"elementVisible"> =
        createElementVisibleEvent(element, entry, {
          metadataOptions: eventMetadataOptions,
        });
      queue.push(elementVisibleEvent);
    });
  });

  const config = { attributes: true, childList: false, subtree: false };
  mutationObserver.observe(element, config);
  window._elementMutationObservers?.set(element, mutationObserver);
};

const detachElementMutationObserver = (element: HTMLElement) => {
  const mutationObserver = window._elementMutationObservers?.get(element);

  if (mutationObserver) {
    mutationObserver.disconnect();
    window._elementMutationObservers?.delete(element);
  }
};

const createIntersectionObserver = (
  element: HTMLElement,
  queue: BrowserEventQueue,
) => {
  /**
   * Threshold array of [0, 0.5] indicates that the IntersectionObserver will happen
   * when the element in question has reached either 0% (totally out of view) or 50% (considering it visible)
   */
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: [0, 0.5],
  };

  let elementIsIntersecting = false;

  const handleIntersectWithQueue = (
    entries: IntersectionObserverEntry[],
    intersectionObserver: IntersectionObserver,
  ) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (
        entry.isIntersecting &&
        entry.intersectionRatio >= 0.5 &&
        !elementIsIntersecting
      ) {
        /**
         * Other than triggering an elementVisible event, we would also want to attach a MutationObserver on the
         * particular element for real-time updates should the viewport be within the boundaries set
         * */
        elementIsIntersecting = true;

        const elementVisibleEvent: BrowserTrackedEvent<"elementVisible"> =
          createElementVisibleEvent(element, entry, {
            metadataOptions: eventMetadataOptions,
          });
        queue.push(elementVisibleEvent);
        attachElementMutationObserver(element, entry, queue);
      }
    });
  };

  const handleIntersect = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
  ) => {
    handleIntersectWithQueue(entries, observer);

    /**
     * In the event that the element goes out of the viewport threshold,
     * we will stop the MutationObserver on it. And by toggling elementIsIntersecting,
     * we will reinstate the IntersectionObserver on the element as well
     */
    const isFullyHidden = entries.some(
      (entry) => entry.intersectionRatio === 0,
    );
    const isPartiallyHidden = entries.some(
      (entry) => entry.intersectionRatio > 0 && entry.intersectionRatio <= 0.5,
    );

    if (isFullyHidden && !isPartiallyHidden) {
      elementIsIntersecting = false;
      detachElementMutationObserver(element);
      intersectionObserver.observe(element);
    }
  };

  const intersectionObserver: IntersectionObserver = new IntersectionObserver(
    handleIntersect,
    options,
  );
  intersectionObserver.observe(element);
};

const attachClickEvents = (
  queue: BrowserEventQueue,
  elements: HTMLElement[],
) => {
  elements.forEach((element: HTMLElement) => {
    // Only if the element has not been flagged to be tracked, we will then proceed to track it
    if (!element._neuronClickTracked) {
      const targetUrl = element.getAttribute("href");
      const elementId = element.id;
      const elementClasses = [...Array.from(element.classList)];
      const elementTarget = element.getAttribute("target");
      const elementText =
        element.innerText?.trim().length > 100
          ? `${element.innerText?.trim().substring(0, 100)}...`
          : element.innerText?.trim();
      const currentWindowUrl = window.location.href;

      const boundingRect: DOMRect = element.getBoundingClientRect();
      const scrollLeft =
        window.pageXOffset ||
        document.documentElement.scrollLeft ||
        document.body.scrollLeft;
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const xCoordinate = boundingRect.left + scrollLeft;
      const yCoordinate = boundingRect.top + scrollTop;
      const domPath: string = getElementFullDOMPath(element);
      const clickCategory: string = getClickMappingFromElementID(elementId);
      const adCampaignAttributes: AdCampaignAttributes = {
        adLineItemID: element.getAttribute("ads-lineitem-id"),
        adAdvertiserID: element.getAttribute("ads-advertiser-id"),
        adCampaignID: element.getAttribute("ads-campaign-id"),
        adCreativeID: element.getAttribute("ads-creative-id"),
        dataGoogleQueryID: element.getAttribute("data-google-query-id"),
      };

      element.addEventListener("click", () => {
        const eventClickData: ClickEventData = {
          targetUrl: targetUrl,
          elementId: elementId,
          elementClasses: elementClasses,
          elementTarget: elementTarget,
          elementText: elementText,
          currentWindowUrl: currentWindowUrl,
          clickCategory: clickCategory,
          xCoordinate: xCoordinate,
          yCoordinate: yCoordinate,
          fullDOMPath: domPath,
          campaignAttributes: adCampaignAttributes,
        };
        const browserClickEvent: BrowserTrackedEvent<"click"> = {
          data: eventClickData,
          eventDateTime: new Date().toISOString(),
          eventType: "click",
          eventId: generateEventId(),
          meta: generateEventMetadata(eventMetadataOptions),
        };
        queue.push(browserClickEvent);
      });
      element._neuronClickTracked = true;
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = (func: { (): void; apply?: any }, delay: number) => {
  let debounceTimer: string | number | NodeJS.Timeout | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: any, ...args: any[]) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, ...args), delay);
  };
};

const addScrollDepthEventsToQueue = (
  queue: BrowserEventQueue,
  lastKnownScrollPercentagePosition: number,
) => {
  lastKnownScrollPercentagePosition = calculateScrollPercentage();
  if (lastKnownScrollPercentagePosition > 0.0) {
    fireScrollDepthEvents(lastKnownScrollPercentagePosition, queue, {
      metadataOptions: eventMetadataOptions,
    });
  } else {
    lastKnownScrollPercentagePosition = 0;
    fireScrollDepthEvents(lastKnownScrollPercentagePosition, queue, {
      metadataOptions: eventMetadataOptions,
    });
  }
};

const addPermutiveEventToQueue = (queue: BrowserEventQueue) => {
  const permutiveEvent: BrowserTrackedEvent<"permutive"> | null =
    createPermutiveEvent({ metadataOptions: eventMetadataOptions });
  if (permutiveEvent) {
    queue.push(permutiveEvent);
  }
};

const addUnfocusedPageOpenEventToQueue = (queue: BrowserEventQueue) => {
  if (!document.hasFocus()) {
    /*
      During the page load, should the page not be in focused, we would want to fire
      an event to indicate that the user is not actively on the page. 
    */
    const unfocusedPageOpenEvent: BrowserTrackedEvent<"unfocusedPageOpen"> =
      createUnfocusedPageOpenEvent({ metadataOptions: eventMetadataOptions });
    queue.push(unfocusedPageOpenEvent);
  }
};

const addDataLayerEventToQueue = (
  queue: BrowserEventQueue,
  dataLayerEventType: string,
  eventData: object,
) => {
  const dataLayerEvent: BrowserTrackedEvent<`dataLayer.${typeof dataLayerEventType}`> =
    createDataLayerEvent(dataLayerEventType, eventData, {
      metadataOptions: eventMetadataOptions,
    });
  queue.push(dataLayerEvent);
};

const addLoadEventToQueue = (queue: BrowserEventQueue) => {
  const browserLoadEvent: BrowserTrackedEvent<"load"> = createLoadEvent({
    metadataOptions: eventMetadataOptions,
  });
  queue.push(browserLoadEvent);
};

const addResumeEventToQueue = (queue: BrowserEventQueue) => {
  const browserResumeEvent: BrowserTrackedEvent<"resume"> = createResumeEvent({
    metadataOptions: eventMetadataOptions,
  });
  queue.push(browserResumeEvent);
};

const addStartupEventsToQueue = (
  queue: BrowserEventQueue,
  lastKnownScrollPercentagePosition: number,
) => {
  addLoadEventToQueue(queue);
  addScrollDepthEventsToQueue(queue, lastKnownScrollPercentagePosition);
  addUnfocusedPageOpenEventToQueue(queue);
  addPermutiveEventToQueue(queue);
  persistInitialTimestamp();
};

const constructQueue = (
  config: BrowserConfig,
  token: TokenValue,
): BrowserEventQueue => {
  // Creates the constructor object for BrowserEventQueue.
  const queueConstructor: Record<string, unknown> = {
    collectorUrl: new URL(config.sessionApiUrl).toString(),
    sessionToken: token,
    apiToken: config.sessionApiToken,
  };

  const queue = new BrowserEventQueue(
    queueConstructor as unknown as BrowserQueueConfig,
  );
  return queue;
};

const setupListeners = (
  config: BrowserConfig,
  queue: BrowserEventQueue,
): void => {
  /*
    Attaching an event listener for scroll depth checks.
  */
  let lastKnownScrollPercentagePosition = 0;
  document.addEventListener(
    "scroll",
    debounce(function () {
      lastKnownScrollPercentagePosition = calculateScrollPercentage();
      fireScrollDepthEvents(lastKnownScrollPercentagePosition, queue, {
        metadataOptions: eventMetadataOptions,
      });
    }, config.flushRate ?? defaultFlushRateInMS),
  );

  /*
    Attaching an event listener on DOMContentLoaded to add startup events
    such as page load, etc.
  */
  if (document.readyState === "loading") {
    document.addEventListener(
      "DOMContentLoaded",
      () => {
        addStartupEventsToQueue(queue, lastKnownScrollPercentagePosition);
      },
      { once: true },
    );
  } else {
    addStartupEventsToQueue(queue, lastKnownScrollPercentagePosition);
  }

  const options = {
    metadataOptions: eventMetadataOptions,
  };
  attachEventListeners(queue);
  attachUserSpecifiedListeners(config, queue);
  attachPodcastListeners(queue);
  featureEP2.initialize(queue, options);
  featureCavaiAdsTrackEvent.initialize(queue, options);
  decorateOutgoingLinks(neuronConfig.whiteListedDomains);
  queue.startFlush(config.flushRate ?? defaultFlushRateInMS);

  /*
    Use a MutationObserver to watch for changes in the DOM so that we can attach listeners
    on dynamically-added elements after the first initialization.
  */

  if (MutationObserver) {
    if (
      window._smtObserver &&
      window._smtObserver instanceof MutationObserver
    ) {
      window._smtObserver.disconnect();
    } else {
      window._smtObserver = undefined;
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        attachUserSpecifiedListeners(config, queue);
      });
    });

    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    window._smtObserver = observer;
  }
};

function dispatchCustomEvent(eventName: string, data?: object) {
  const event = new CustomEvent(eventName, { detail: data });
  dispatchEvent(event); // built-in function
}

export const initBrowser = async (
  config: BrowserConfig,
): Promise<void | {
  track: (event: BrowserTrackedEvent<BrowserEventType>) => void;
  queue: BrowserEventQueue;
}> => {
  try {
    parseIncomingLink();
    generateVisitId();
    const neuronId: string = getNeuronId();
    const token: Promise<TokenValue> = getToken(
      config.sessionApiUrl,
      config.sessionApiToken,
      neuronId,
      "script",
    );

    const useDataLayer = !!config?.experimental?.articleMetadata?.useDataLayer;

    if (useDataLayer) {
      eventMetadataOptions.onGetDataLayer = onGetDataLayer;
      eventMetadataOptions.onGenerateArticleMetadata =
        onGenerateMagazineArticleMetadata;
    }

    const queue: BrowserEventQueue = constructQueue(config, await token);
    setupListeners(config, queue);

    dispatchCustomEvent("neuron:ready");

    return {
      track: (event: BrowserTrackedEvent<BrowserEventType>) => {
        queue.push(event);
      },
      queue,
    };
  } catch (error) {
    // Avoid crashing the page at all costs.
    console.error("Error has occurred: ", error);
    return;
  }
};
