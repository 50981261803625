import { DeviceInformation } from "@browser/event";

export const getDeviceInformation = (): DeviceInformation => {
  const userAgent: string = window.navigator?.userAgent;
  const userPreferredLanguage: string = window.navigator?.language;
  const screenHeight: number = window.innerHeight;
  const screenWidth: number = window.innerWidth;
  const screenMaxTouchPoints: number = window.navigator?.maxTouchPoints;
  const screenDeviceOrientation: string = window.screen?.orientation?.type;

  const deviceInformation = {
    userAgent: userAgent,
    userPreferredLanguage: userPreferredLanguage,
    screenHeight: screenHeight,
    screenWidth: screenWidth,
    screenMaxTouchPoints: screenMaxTouchPoints,
    screenOrientation: screenDeviceOrientation,
  };

  return deviceInformation;
};
