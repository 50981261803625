import { CreateEventOptions } from "@core/event";
import { Queuable } from "@core/queue";
import { applyHighwaySchema, CustomEventHandler } from "@helper/helper";
import { getUrl } from "@helper/location";
import { getReferrer } from "@helper/referrer";

// --- types ---

export enum EVENTS {
  PAGES_PAGEVIEW = "pages_page_view",
}

// --- private properties ----

let _pageviewHandler: CustomEventHandler;
let _options: CreateEventOptions;

// --- public functions ---

function initialize<T extends Queuable>(
  queue: T,
  options: CreateEventOptions,
): void {
  _pageviewHandler = createPageviewHandler(queue);
  _options = options;

  window.addEventListener(EVENTS.PAGES_PAGEVIEW, _pageviewHandler);
}

function cleanup() {
  window.removeEventListener(EVENTS.PAGES_PAGEVIEW, _pageviewHandler);
}

export default {
  initialize,
  cleanup,
};

// ---- private functions ----

function createPageviewHandler(queue: Queuable) {
  return (event: CustomEvent) => {
    const transformedEvent = applyHighwaySchema(
      {
        eventType: "load",
        data: {
          currentWindowUrl: getUrl(),
          referrer: getReferrer(),
          pageTitle: event.detail.issue_name,
        },
      },
      _options,
    );

    queue.push(transformedEvent);
  };
}
