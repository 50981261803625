import { BrowserEventQueue } from "@browser/browserQueue";
import { BrowserTrackedEvent, ScrollDepthEventData } from "@browser/event";
import { generateEventId } from "@browser/plugins/ids/eventId";
import { CreateEventOptions } from "@core/event";
import { generateEventMetadata } from "@helper/helper";

export const calculateScrollPercentage = () => {
  const windowYScroll = getWindowYScroll();
  const windowHeight = getWindowHeight();
  const documentHeight = getDocumentHeight();

  const lastKnownScrollPercentagePosition =
    ((windowYScroll + windowHeight) / documentHeight) * 100.0;

  return lastKnownScrollPercentagePosition;
};

const getWindowHeight = () => {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight ||
    0
  );
};

const getWindowYScroll = () => {
  return (
    window.scrollY ||
    document.body.scrollTop ||
    document.documentElement.scrollTop ||
    0
  );
};

const getDocumentHeight = () => {
  return Math.max(
    document.body.scrollHeight || 0,
    document.documentElement.scrollHeight || 0,
    document.body.offsetHeight || 0,
    document.documentElement.offsetHeight || 0,
    document.body.clientHeight || 0,
    document.documentElement.offsetHeight || 0,
  );
};

let maxScrollDepth = 0.0;
const scrollDepthPercentageBreakpoints: Array<number> = [
  10.0, 20.0, 30.0, 40.0, 50.0, 60.0, 70.0, 80.0, 90.0, 100.0,
];
const scrollDepthBreakpoints: Record<number, boolean> =
  scrollDepthPercentageBreakpoints.reduce(
    (acc, val) => ({ ...acc, [val]: false }),
    {} as Record<number, boolean>,
  );

export const fireScrollDepthEvents = (
  scrollDepth: number,
  queue: BrowserEventQueue,
  options?: CreateEventOptions,
) => {
  if (scrollDepth <= maxScrollDepth) {
    return;
  }

  for (const breakpoint in scrollDepthBreakpoints) {
    const breakpointInNumber = Number.parseFloat(breakpoint);
    if (breakpointInNumber > scrollDepth) {
      return;
    }

    const breakpointMet = scrollDepthBreakpoints[breakpoint];
    if (!breakpointMet) {
      scrollDepthBreakpoints[breakpoint] = true;
      maxScrollDepth = breakpointInNumber;

      const scrollDepthEventData: ScrollDepthEventData = {
        currentWindowUrl: window.location.href,
        pageTitle: document.title,
        scrollDepthPercentage: breakpointInNumber,
      };

      const scrollDepthEvent: BrowserTrackedEvent<"scrollDepth"> = {
        data: scrollDepthEventData,
        eventDateTime: new Date().toISOString(),
        eventType: "scrollDepth",
        meta: generateEventMetadata(options?.metadataOptions),
        eventId: generateEventId(),
      };
      queue.push(scrollDepthEvent);
    }
  }
};
