import neuronConfig from "@config/config.json";

export const getSiteId = () => {
  const domainToSiteMap: Record<string, string> = neuronConfig.domainToSiteMap;
  const hostname = window.location.hostname;
  const parts = hostname.split(".");

  if (parts.length <= 2) {
    return domainToSiteMap[hostname] ?? "";
  } else {
    /**
     * These are the only exceptions to the domain mapping
     * as the actual domain here is sph.com.sg/other forms of sph.com.sg, and the team is using contest as a subdomain.
     * As we do not want to map all sph.com.sg domains to contest_sg, this is the only exception
     * whereby we do an explicit equality value check to the hostname
     *
     * contest.sph.com.sg for PROD
     * contest-frontend-uat.sph.com.sg for STAGING
     * eShop URLs for all environments
     */
    if (
      hostname === "contest.sph.com.sg" ||
      hostname === "contest-frontend-uat.sph.com.sg"
    ) {
      return "contest_sg";
    } else if (
      hostname === "eshop.crsm.dev.ems.sphdigital.com.sg" ||
      hostname === "uattmp.eshop4-cms.sph.com.sg" ||
      hostname === "subscribe.sph.com.sg"
    ) {
      return "eshop_sg";
    }

    const rootDomain = parts.slice(1).join(".");
    return domainToSiteMap[rootDomain] ?? "";
  }
};
