import { v4 as uuidv4 } from "uuid";

export type eventId = string;

export const generateEventId = (): string => {
  /**
   * Returns a randomly-generated eventId that is used for every single event.
   * eventId is generated using the uuid module, specifically Version 4 was chosen as
   * it requires a completely random value.
   * (See - https://en.wikipedia.org/wiki/Universally_unique_identifier#Version_4_(random))
   *
   * @remarks
   * Creates the eventId for every event that would be sent over to the API
   * @returns A uniquely random generated eventId.
   */
  return uuidv4();
};
