export { onGenerateArticleMetadata as onGenerateMagazineArticleMetadata } from "./magazine";

export const onGetDataLayer = (): Record<string, unknown> => {
  if (
    "dataLayer" in window === false ||
    !window.dataLayer ||
    !Array.isArray(window.dataLayer)
  ) {
    return {};
  }

  const dataLayer = window.dataLayer as Array<Record<string, unknown>>;
  return dataLayer.find((data) => data.event === "pageview") ?? {};
};
