import { SessionStorageKey } from "@browser/browserQueue";
import { removeToken } from "@browser/token";
import neuronConfig from "@config/config.json";

import { v4 as uuidv4 } from "uuid";

export type NeuronId = string | undefined;

export const getNeuronId = (): string => {
  /**
   * Returns a randomly-generated NeuronId that is used for subsequent event payloads.
   * NeuronId is generated using the uuid module, specifically Version 4 was chosen as
   * it requires a completely random value.
   * (See - https://en.wikipedia.org/wiki/Universally_unique_identifier#Version_4_(random))
   *
   * @remarks
   * Creates the Neuron Session ID that will tie all blocks of activities together
   * @returns A uniquely random generated NeuronId.
   */
  removeOldNeuronCookie();
  const neuronIDFromCookie = getCookieWithID(neuronConfig.neuronId);
  if (!neuronIDFromCookie) {
    // If there is no cookie, we need to clear the current token to avoid mismatchs.
    removeToken();
    const neuronId = setNeuronId();
    return neuronId;
  }
  return neuronIDFromCookie;
};

export const setNeuronId = (incomingNeuronId?: string): string => {
  /**
   * Cookie expiration is currently set at 400 days from the current date.
   * This is with accordance to:
   * https://www.cookiestatus.com/#:~:text=Cookies%20restricted%20to%20a%20maximum%20lifetime%20of%20400%20days.
   *
   * Browsers set a hard expiry between 7 and 400 days. Hence, we are taking 400 days as the maximum now.
   */
  const todayDate = new Date();
  const futureDate = todayDate.setDate(
    todayDate.getDate() + neuronConfig.cookieExpirationDays,
  );

  const valueToSetForNeuronId: string =
    incomingNeuronId !== undefined && incomingNeuronId !== ""
      ? incomingNeuronId
      : uuidv4();
  document.cookie = `${neuronConfig.neuronId}=${encodeURIComponent(
    valueToSetForNeuronId,
  )}; expires=${new Date(futureDate).toUTCString()}; path=/;`;

  return valueToSetForNeuronId;
};

export const getLinkDecoratedNeuronId = (): string | null => {
  /**
   * Incoming NeuronId (_nei) from decorated outgoing links
   * would be stored in the Session Storage. This method
   * retrieves any incoming NeuronId that has been stored.
   */
  return window.sessionStorage.getItem(SessionStorageKey.IncomingNeuronId);
};

/*
  This is to remove all erroneous cookies with neuron-id as the key previously,
  As we are not allowed to see the path of each cookie, we will have to loop through
  all cookies and when the cookie is present, we will remove the cookie.

  As we are using a new cookie named neuronId and the path has been set properly, this
  should eliminate this issue.
*/
const removeOldNeuronCookie = (): void => {
  const cookies = document.cookie.split("; ");
  cookies.forEach((cookie) => {
    if (cookie.trim().indexOf(`neuron-id=`) === 0) {
      document.cookie = `neuron-id=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    }
  });
};

export const getCookieWithID = (cookieName: string): string | null => {
  let cookieToReturn: string | null = null;
  document.cookie.split("; ").forEach((cookie) => {
    const [key, value] = cookie.split("=");
    if (key.trim() === cookieName) {
      cookieToReturn = value;
    }
  });
  return cookieToReturn;
};
